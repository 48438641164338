<!--
 * @Author: mulingyuer
 * @Date: 2021-08-24 15:12:17
 * @LastEditTime: 2021-09-07 11:38:34
 * @LastEditors: mulingyuer
 * @Description: 转移分类弹窗
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\course-category-manage\MoveDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="转移" :visible.sync="show" width="500px" class="dialog-verticalmove-dialog" @close="onClose"
    @open="onOpen">
    <div class="content" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" size="medium"
        class="medium-form">
        <el-form-item label="目标分类：" prop="target_id">
          <el-select v-model="form.target_id" placeholder="请选择目标分类">
            <el-option v-for="op in categoryArr" :key="op.id" :label="op.name" :value="op.id"
              :disabled="moveId===op.id"></el-option>
          </el-select>
          <div class="info">
            <i class="el-icon-info"></i>将素材转移至选择的分类
          </div>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show=false">取消</el-button>
      <el-button v-if="!loading" size="small" type="primary" :loading="saveLoading"
        @click="onConfirm('ruleForm')">确认
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import { category, moveCategory } from "../../api/course-category-manage";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //迁移的id
    moveId: {
      type: [Number, String],
      required: true,
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //加载中
      form: {
        target_id: "", //分类id
      },
      rules: {
        target_id: [
          { required: true, message: "请选择分类", trigger: "change" },
        ],
      },
      categoryArr: [], //分类选项数据
      saveLoading: false, //保存中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //关闭
    onClose() {
      this.resetForm();
    },
    //打开
    onOpen() {
      this.$nextTick(() => {
        this.resetForm();
        this.getCategoryArr();
      });
    },
    //确认
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = { id: this.moveId, ...this.form };
          moveCategory(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);

              this.complete();
              this.saveLoading = false;
              this.show = false;
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
    //获取分类选项数据
    getCategoryArr() {
      this.loading = true;
      category()
        .then((res) => {
          const { data } = res;
          this.categoryArr = data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
