<!--
 * @Author: mulingyuer
 * @Date: 2021-09-07 17:09:08
 * @LastEditTime: 2021-09-08 14:27:14
 * @LastEditors: mulingyuer
 * @Description: 素材弹窗
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\course-manage\add-edit\MaterialDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="选择素材" :visible.sync="open" width="800px" class="dialog-vertical material-dialog"
    @open="onOpen" @close="onClose">
    <div class="content" v-loading="loading">
      <el-form ref="ruleForm" :model="postData" :rules="rules" label-width="0px" size="medium"
        class="medium-form">
        <div class="head">
          <div class="left">
            <el-form-item prop="category_id">
              <el-select v-model="postData.category_id" placeholder="请选择素材分类">
                <el-option v-for="op in categoryArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item prop="keyword">
              <el-input v-model="postData.keyword" placeholder="请输入内容"></el-input>
            </el-form-item>
          </div>
          <div class="right">
            <el-button size="medium" @click="onAdd">新增素材</el-button>
            <el-button size="medium" @click="onReset">重置</el-button>
            <el-button type="primary" size="medium" @click="onSearch">搜索</el-button>
          </div>
        </div>
      </el-form>
      <div class="body">
        <el-table class="thead-light" :class="{'hide-selection':!options.multiple}" :data="tbody"
          style="width: 100%" stripe @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" :selectable="onSelectable">
          </el-table-column>
          <el-table-column v-for="th in thead" :key="th.prop" :prop="th.prop" :label="th.label"
            :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed" :show-overflow-tooltip="th.tooltip">
            <template slot-scope="{row}">
              <template>{{row[th.prop]|placeholder}}</template>
            </template>
          </el-table-column>
        </el-table>
        <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
      </div>
    </div>
    <template #footer>
      <el-button size="small" @click="open=false">取消</el-button>
      <el-button v-if="!loading" size="small" type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Pagination from "@/base/components/Default/Pagination";
import { category } from "../../../api/material-manage/index";
import { list } from "../../../api/course-manage/add-edit";
export default {
  data() {
    return {
      open: false, //是否显示弹窗
      loading: false, //获取中
      postData: {
        category_id: "", //分类id
        keyword: "", //素材名称
      },
      rules: {},
      categoryArr: [], //分类选项数据
      thead: [
        { label: "素材名称", prop: "title", minWidth: 200, tooltip: true },
        { label: "分类", prop: "category_name", minWidth: 150, tooltip: true },
      ],
      tbody: [],
      pageData: {},
      activeRows: [], //选中的id
      //弹窗配置
      options: {
        multiple: true, //是否多选
      },
    };
  },
  methods: {
      //上传
    onAdd() {
      this.$router.push({ name: "ClassroomMaterialManageAddEdit" });
    },
    //获取分类选项数据
    getCategoryArr() {
      return new Promise((resolve, reject) => {
        category()
          .then((res) => {
            const { data } = res;
            this.categoryArr = data;

            return resolve(true);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    //获取素材列表
    getList() {
      return new Promise((resolve, reject) => {
        list(this.postData)
          .then((res) => {
            const { data } = res;
            this.pageData = data;
            this.tbody = data.data;

            return resolve(true);
          })
          .catch((error) => {
            return reject(error);
          });
      });
    },
    //打开弹窗回调
    async onOpen() {
      try {
        this.loading = true;
        await this.getCategoryArr();
        await this.getList();

        this.loading = false;
      } catch (err) {
        this.loading = false;
      }
    },
    //关闭弹窗回调
    onClose() {
      this.show = false;
      //还原数据
      this.confirm = null;
      Object.assign(this.$data, this.$options.data());
    },
    //搜索
    onSearch() {
      this.loading = true;
      this.getList().finally(() => {
        this.loading = false;
      });
    },
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      this.onSearch();
    },
    //table多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeRows = arr;
      } else {
        this.activeRows = [];
      }
    },
    //是否允许单个勾选
    onSelectable(row, index) {
      if (this.options.multiple) return true;

      let flag = false;
      if (this.activeRows.length === 0) {
        flag = true;
      } else {
        const isActive = this.activeRows.find((item) => item.id === row.id);
        if (isActive && isActive.id === row.id) {
          flag = true;
        } else {
          flag = false;
        }
      }
      return flag;
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
    //打开弹窗
    openDialog({ confirm = () => {}, options = {} } = {}) {
      Object.assign(this.options, options);
      this.confirm = confirm; //记录回调
      this.open = true;
    },
    //确认
    onConfirm() {
      if (this.confirm) this.confirm(this.activeRows);
      this.open = false;
    },
  },
  created() {
    //注册监听bus
    this.$bus.on("open-course-material-dialog", this.openDialog);
  },
  beforeDestroy() {
    //注销监听bus
    this.$bus.off("open-course-material-dialog", this.openDialog);
  },
  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.material-dialog {
  .content {
    padding-top: 20px;
    padding-bottom: 20px;
    .head {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      margin-bottom: 20px;
      .el-form-item {
        margin-bottom: 0;
        & + .el-form-item {
          margin-left: 20px;
        }
      }
      .left {
        min-width: 0;
        // flex-grow: 1;
        display: flex;
      }
      .right {
        margin-left: 10px;
        flex-shrink: 0;
      }
    }
    .body {
      .hide-selection {
        ::v-deep thead .el-table-column--selection .cell {
          display: none;
        }
      }
    }
  }
}
</style>
