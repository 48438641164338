<!--
 * @Author: mulingyuer
 * @Date: 2021-08-31 17:01:16
 * @LastEditTime: 2021-09-02 14:55:56
 * @LastEditors: mulingyuer
 * @Description: 上传&编辑素材
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\material-manage\add-edit.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="add-edit">
    <div class="content" v-loading="loading">
      <MaterialUpload :isEdit="isEdit" :complete="onUpComplete" />
      <MateriaItem :form="form" @delete="onDelete" ref="MateriaItem" />
      <AttributeSetting :form="form" ref="AttributeSetting" />
    </div>
    <FixedActionBar :z-index="2">
      <!-- <el-button v-if="!loading" type="primary" size="medium" :loading="saveLoading">
        保存并开课
      </el-button> -->
      <el-button v-if="!loading" type="primary" size="medium" :loading="saveLoading" @click="onSave">
        保存
      </el-button>
      <el-button size="medium" @click="onCancel">
        取消
      </el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import MaterialUpload from "../../components/material-manage/add-edit/MaterialUpload.vue";
import MateriaItem from "../../components/material-manage/add-edit/MateriaItem.vue";
import AttributeSetting from "../../components/material-manage/add-edit/AttributeSetting.vue";
import { save, detail } from "../../api/material-manage/add-edit";
export default {
  data() {
    return {
      loading: false, //加载中
      upDate: [], //上传的数据
      form: {
        reses: [], //每个素材的表单
        for_people: "", //适用人群
        description: "", //简介
      },
      saveLoading: false, //保存中
    };
  },
  computed: {
    //素材id
    materialId({ $route }) {
      return $route.query.materialId;
    },
    //是否编辑模式
    isEdit({ materialId }) {
      return typeof materialId !== "undefined";
    },
  },
  methods: {
    //上传完成
    onUpComplete(data) {
      const { isRepeat, filterArr } = this.isRepeatOrFilter(data);
      if (isRepeat) this.$message.warning(`请勿选择相同文件`);

      if (this.isEdit) {
        //编辑模式
        this.form.reses = this.createFormData(filterArr);
        this.form.reses[0].id = this.materialId; //重设id, create创建后不带id
        this.upDate = filterArr;
      } else {
        //上传模式
        this.form.reses.push(...this.createFormData(filterArr));
        this.upDate.push(...filterArr); //每次都是新增上传文件
      }
    },
    //是否存在+过滤 {isRepeat,filterArr}
    isRepeatOrFilter(data) {
      const mainIds = this.upDate.map((item) => item.id);
      const dataIds = data.map((item) => item.id);

      const isRepeat = dataIds.some((id) => mainIds.includes(id));
      const filterArr = data.filter((item) => !mainIds.includes(item.id));

      return { isRepeat, filterArr };
    },
    //生成表单绑定数据
    createFormData(data) {
      const createArr = data.map((item) => {
        return {
          id: "",
          title: item.title,
          cover: require('@/modules/online-classroom/assets/images/class_cover.png'), //缩略图
          category_id: "", //分类
          type: item.type, //
          res: item,
        };
      });

      return createArr;
    },
    //删除
    onDelete(id) {
      //upData中删除
      const upIndex = this.upDate.findIndex((item) => item.id === id);
      if (upIndex !== -1) this.upDate.splice(upIndex, 1);
      //表单中删除
      const formIndex = this.form.reses.findIndex((item) => item.res.id === id);
      if (formIndex !== -1) this.form.reses.splice(formIndex, 1);
    },
    //保存
    async onSave() {
      try {
        this.saveLoading = true;
        //校验
        let validList = [this.$refs.MateriaItem, this.$refs.AttributeSetting];
        validList = validList.map((item) => item.validator());
        await Promise.all(validList);
        //请求
        const postData = { ...this.form };
        const { msg } = await save(postData);

        this.$message.success(msg);
        this.saveLoading = false;
        this.$router.go(-1);
      } catch (error) {
        this.saveLoading = false;
      }
    },
    //取消
    onCancel() {
      this.$router.go(-1);
    },
    //获取编辑的数据
    getDetail() {
      this.loading = true;
      const postData = { id: this.materialId };
      detail(postData)
        .then((res) => {
          const { data } = res;
          this.form = data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  created() {
    if (this.isEdit) {
      this.getDetail();
    }
  },
  beforeRouteEnter(to, from, next) {
    const materialId = to.query.materialId;
    if (typeof materialId !== "undefined") {
      to.meta.title = "编辑素材";
    } else {
      to.meta.title = "素材上传";
    }
    next();
  },
  components: {
    FixedActionBar,
    MaterialUpload,
    MateriaItem,
    AttributeSetting,
  },
};
</script>

<style lang="scss" scoped>
</style>
