<!--
 * @Author: mulingyuer
 * @Date: 2021-08-24 11:02:31
 * @LastEditTime: 2021-09-10 16:48:43
 * @LastEditors: mulingyuer
 * @Description: 新增或编辑分类
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\course-category-manage\AddEditCatDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog :title="isEdit?'编辑':'新增'" :visible.sync="show" width="500px"
    class="dialog-vertical add-edit-cat-dialog" @close="onClose" @open="onOpen">
    <div class="content" v-loading="loading">
      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="80px" size="medium"
        class="medium-form">
        <el-form-item label="名称：" prop="name">
          <el-input v-model="form.name" placeholder="请输入分类名称"></el-input>
        </el-form-item>
<!--        <el-form-item v-if="editData.is_system===0 || !this.isEdit" label="上级分类：" prop="pid">-->
<!--          <el-select v-model="form.pid" placeholder="请选择上级分类" :disabled="isAddChild" clearable>-->
<!--            <el-option v-for="op in categoryArr" :key="op.id" :label="op.name" :value="op.id"></el-option>-->
<!--          </el-select>-->
<!--        </el-form-item>-->
<!--        <el-form-item label="图标：" prop="icon">-->
<!--          <el-input v-show="false" v-model="form.icon"></el-input>-->
<!--          <ImageItem v-model="form.icon" width="100px" height="100px" />-->
<!--          <div class="info">-->
<!--            <i class="el-icon-info"></i>建议尺寸为100px*100px-->
<!--          </div>-->
<!--        </el-form-item>-->
        <el-form-item v-show="false" prop="id">
          <input type="hidden" :value="form.id" />
        </el-form-item>
        <el-form-item label="显示：" prop="status">
          <el-radio-group v-model="form.status">
            <el-radio :label="1">显示</el-radio>
            <el-radio :label="0">不显示</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button size="small" @click="show=false">取消</el-button>
      <el-button v-if="!loading" size="small" type="primary" :loading="saveLoading"
        @click="onConfirm('ruleForm')">确认
      </el-button>
    </template>
  </el-dialog>
</template>

<script>
import ImageItem from "@/modules/common/components/ImageItem.vue";
import { category, addCategory } from "../../api/course-category-manage";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //编辑的数据
    editData: {
      type: Object,
      default: () => {},
    },
    //指定pid上级分类
    fixPid: {
      type: String,
    },
    //模式
    mode: {
      type: String,
      required: true,
      validator(value) {
        return ["edit", "create", "addChild"].includes(value);
      },
    },
    //完成后的回调
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      form: {
        id: "", //为空字符时新增
        name: "", //名称
        pid: "", //上级分类id
        icon: "", //图标
        status: 1, //状态
      },
      rules: {
        name: [{ required: true, message: "请输入分类名称", trigger: "blur" }],
        // pid: [{ required: true, message: "请选择上级分类", trigger: "change" }],
        icon: [
          { required: true, message: "请上传分类图标", trigger: "change" },
        ],
      },
      categoryArr: [], //分类选项数据
      saveLoading: false, //保存中
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
    //是否添加子类
    isAddChild({ mode }) {
      return mode === "addChild";
    },
    //是否编辑
    isEdit({ mode }) {
      return mode === "edit";
    },
  },
  methods: {
    //关闭
    onClose() {
      this.resetForm();
    },
    //打开
    onOpen() {
      this.$nextTick(() => {
        this.resetForm();
        switch (this.mode) {
          case "create": //新增
            this.reset();
            this.getCategoryArr();
            break;
          case "edit": //编辑
            this.initEditData();
            this.getCategoryArr();
            break;
          case "addChild": //添加子类
            this.form.pid = this.fixPid;
            this.getCategoryArr();
            break;
          default:
            console.error(`未知的弹窗模式：${this.mode}`);
        }
      });
    },
    //确认
    onConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true;
          const postData = { ...this.form };
          addCategory(postData)
            .then((res) => {
              const { msg } = res;
              this.$message.success(msg);

              this.complete();
              this.saveLoading = false;
              this.show = false;
            })
            .catch(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    reset() {
      /**
       *      form: {
        id: "", //为空字符时新增
        name: "", //名称
        pid: "", //上级分类id
        icon: "", //图标
        status: 1, //状态
      },
       */
      this.form.id = ''
      this.form.name = ''
      this.form.pid = ''
      this.form.icon = ''
      this.form.status = 1
    },
    resetForm() {
      this.$refs["ruleForm"].resetFields();
    },
    //获取分类选项数据
    getCategoryArr() {
      this.loading = true;
      //编辑模式才需要提交自身id
      const postData = { crr_id: this.isEdit ? this.editData.id : "" };
      category(postData)
        .then((res) => {
          const { data } = res;
          this.categoryArr = data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //初始化编辑数据
    initEditData() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.editData[key];
      });
    },
  },
  components: {
    ImageItem,
  },
};
</script>

<style lang="scss" scoped>
</style>
