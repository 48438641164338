<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 15:18:51
 * @LastEditTime: 2021-09-10 16:45:00
 * @LastEditors: mulingyuer
 * @Description: 课程分类
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\course-category-manage.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page course-category-manage">
<!--    <div class="head">-->
<!--      <div class="head-btn">-->
<!--        <Alert />-->
<!--      </div>-->
<!--    </div>-->
    <div class="content" v-loading="loading">
      <div class="content-btn">
        <el-button type="primary" size="small" @click="onAdd">添加</el-button>
      </div>
      <el-table class="thead-light" :data="tbody" stripe size="medium" style="width: 100%" row-key="id"
        :tree-props="{children: '_children'}" @sort-change="onSortChange">
        <el-table-column prop="children" label="折叠" width="80"></el-table-column>
        <el-table-column v-for="th in thead" :key="th.prop" :prop="th.prop" :label="th.label"
          :minWidth="th.minWidth" :sortable="th.sort" :show-overflow-tooltip="th.tooltip" :fixed="th.fixed">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
<!--              <el-button type="text" size="mini" v-if="row.is_system===0 && !row.pid"-->
<!--                @click="onAddChildCat(row.id)">-->
<!--                添加子类-->
<!--              </el-button>-->
              <el-button type="text" size="mini" @click="onEdit(row)">编辑</el-button>
              <el-button type="text" size="mini" @click="onMove(row.id)">转移</el-button>
              <el-button type="text" size="mini" v-if="row.is_system===0" @click="onDelete(row.id)">删除
              </el-button>
            </template>
            <!-- 排序 -->
            <template v-else-if="th.prop==='sort'">
              <PopoverInputEdit v-model="row.sort" input-type="number" :validator="sortValidator"
                @confirm="onConfirm(row)" />
            </template>
            <!-- 状态 -->
            <template v-else-if="th.prop==='status'">
              <el-checkbox v-model="row[th.prop]" :true-label="1" :false-label="0"
                @change="onStatusChange(row.id,row[th.prop])"></el-checkbox>
            </template>
            <!-- 图标 -->
            <template v-else-if="th.prop==='icon'">
              <div class="icon">
                <img v-miniSrc="row[th.prop]">
              </div>
            </template>
            <!-- 其他 -->
            <template v-else>{{row[th.prop]|placeholder}}</template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 新增&编辑分类 -->
    <AddEditCatDialog v-model="openAddEditCatDialog" :editData="addEditCatDialogData" :fixPid="fixPid"
      :mode="dialogMode" :complete="getList" />
    <!-- 迁移弹窗 -->
    <MoveDialog v-model="openMoveDialog" :moveId="moveDialogData" :complete="getList" />
  </div>
</template>

<script>
import Alert from "../components/course-category-manage/Alert.vue";
import PopoverInputEdit from "@/base/components/Popover/PopoverInputEdit.vue";
import MoveDialog from "../components/course-category-manage/MoveDialog.vue";
import AddEditCatDialog from "../components/course-category-manage/AddEditCatDialog.vue";
import { list, changeSort, changeStatus } from "../api/course-category-manage";
import { deleteCategory } from "../api/course-category-manage";
import { deleteMsgbox } from "@/base/shortcuts/msgbox";
export default {
  data() {
    return {
      loading: false,
      postData: {},
      thead: [
        { label: "排序", prop: "sort", minWidth: 80 },
        { label: "名称", prop: "name", minWidth: 150, tooltip: true },
        // { label: "图标", prop: "icon", minWidth: 100 },
        {
          label: "素材数量",
          prop: "courses_count",
          minWidth: 100,
          sort: "custom",
        },
        { label: "显示", prop: "status", minWidth: 100 },
        { label: "操作", prop: "operate", minWidth: 230, fixed: "right" },
      ],
      tbody: [],
      activeCategory: [], //选中的分类
      //排序校验器
      sortValidator: {
        type: "integer",
        min: 0,
        message: "最小不能低于0的整数",
        trigger: "blur",
      },
      openAddEditCatDialog: false, //是否显示新增编辑弹窗
      addEditCatDialogData: {}, //编辑的数据
      openMoveDialog: false, //是否显示迁移弹窗
      moveDialogData: "", //迁移的id
      fixPid: "", //新增子类固定的父类id
      dialogMode: "create", //弹窗模式
    };
  },
  methods: {
    //获取列表
    getList(pageData) {
      this.loading = true;
      list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.tbody = data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //排序事件 descending=倒序，ascending=升序,api默认倒序，所以null=descending
    onSortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      Object.assign(this.postData, { order_by: prop, is_desc });
      this.getList();
    },
    //修改排序
    onConfirm({ id, sort }) {
      this.loading = true;
      const postData = { id, sort };
      changeSort(postData)
        .then((res) => {
          const { msg } = res;
          this.$message.success(msg);

          this.getList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //修改状态
    onStatusChange(id, status) {
      this.loading = true;
      const pageData = { id, status };
      changeStatus(pageData)
        .then((res) => {
          const { msg } = res;
          this.$message.success(msg);

          this.getList();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //新增
    onAdd() {
      this.dialogMode = "create";
      this.fixPid = "";
      this.addEditCatDialogData = {};
      this.openAddEditCatDialog = true;
    },
    //编辑
    onEdit(row) {
      this.dialogMode = "edit";
      this.fixPid = "";
      this.addEditCatDialogData = row;
      this.openAddEditCatDialog = true;
    },
    //删除
    onDelete(id) {
      deleteMsgbox({
        message: "您是否要删除该条分类数据？",
        confirmCallback: () => {
          return new Promise((resolve, reject) => {
            const postData = { id };
            deleteCategory(postData)
              .then((res) => {
                const { msg } = res;
                this.$message.success(msg);

                this.getList();
                return resolve(true);
              })
              .catch(() => {
                return resolve(true);
              });
          });
        },
      });
    },
    //转移
    onMove(id) {
      this.moveDialogData = id;
      this.openMoveDialog = true;
    },
    //新增子类
    onAddChildCat(id) {
      this.dialogMode = "addChild";
      this.fixPid = id;
      this.addEditCatDialogData = {};
      this.openAddEditCatDialog = true;
    },
  },
  created() {
    this.getList();
  },
  components: {
    Alert,
    PopoverInputEdit,
    MoveDialog,
    AddEditCatDialog,
  },
};
</script>

<style lang="scss" scoped>
.course-category-manage {
  .content {
    .icon {
      width: 50px;
      height: 50px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        vertical-align: top;
      }
    }

    ::v-deep.el-table {
      .el-table__row--level-1 {
        td:nth-child(2) {
          padding-left: 16px;
        }
      }
    }
  }
}
</style>
