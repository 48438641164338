<!--
 * @Author: mulingyuer
 * @Date: 2021-08-23 17:28:40
 * @LastEditTime: 2021-09-03 16:32:03
 * @LastEditors: mulingyuer
 * @Description: 素材管理
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\material-manage\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page material-manage">
    <div class="head">
      <div class="head-btn">
        <el-button size="small" type="primary" @click="onAdd">上传</el-button>
      </div>
      <ManageFilter :filterChange="onFilter" :exportData="postData" />
    </div>
    <div class="content" v-loading="loading">
      <div class="content-tabs">
        <list-tabs v-model="postData.tab" :tabs="tabArr" @tab-click="handleTabClick" />
      </div>
      <div class="content-btn">
        <el-button size="small" @click="onopenChangeCategoryDialog">编辑分类</el-button>
        <el-button size="small" @click="onMultipleDelete">删除</el-button>
        <el-button v-if="postData.tab==='deleted'" type="success" size="small" @click="onMultipleReduction">
          还原
        </el-button>
      </div>
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe @sort-change="sortChange"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column v-for="th in thead" :key="th.prop" :prop="th.prop" :label="th.label"
          :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed" :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
<!--              <el-button type="text" size="mini">开设课程</el-button>-->
              <el-button type="text" size="mini" @click="onEdit(row.id)">编辑</el-button>
              <MoreBtn @command="onMoreCommand">
<!--                <el-dropdown-item :command="{type:'startLog',id:row.id}">开课记录</el-dropdown-item>-->
                <el-dropdown-item :command="{type:'changeLog',id:row.id}">变更记录</el-dropdown-item>
                <el-dropdown-item :command="{type:'delete',id:row.id}">删除</el-dropdown-item>
                <el-dropdown-item v-if="postData.tab==='deleted'" :command="{type:'reduction',id:row.id}">
                  还原
                </el-dropdown-item>
              </MoreBtn>
            </template>
            <!-- 其他 -->
            <template v-else>{{row[th.prop]|placeholder}}</template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    </div>
    <!-- 修改分类弹窗 -->
    <ChangeCategoryDialog v-model="openChangeCategoryDialog" :activeIds="activeIds" :complete="getList" />
  </div>
</template>

<script>
import ManageFilter from "../../components/material-manage/index/Filter.vue";
import Pagination from "@/base/components/Default/Pagination";
import MoreBtn from "@/base/components/Table/MoreBtn";
import ChangeCategoryDialog from "../../components/material-manage/index/ChangeCategoryDialog.vue";
import { list, putBack } from "../../api/material-manage/index";
import { softDelete, handleDel } from "../../api/material-manage/index";
import { deleteMsgbox, reductionMsgbox } from "@/base/shortcuts/msgbox";
export default {
  data() {
    return {
      loading: false,
      postData: {
        tab: "normal",
      },
      tabArr: [
        { label: "全部", name: "normal" },
        { label: "回收站", name: "deleted" },
      ],
      thead: [
        { label: "素材名称", prop: "title", minWidth: 180, tooltip: true },
        { label: "分类", prop: "category_name", minWidth: 120 },
        { label: "素材类型", prop: "type_text", minWidth: 120 },
        { label: "开课数", prop: "courses_count", minWidth: 120 },
        { label: "创建人", prop: "creator_name", minWidth: 120 },
        { label: "创建时间", prop: "create_time", minWidth: 180 },
        { label: "操作", prop: "operate", minWidth: 180, fixed: "right" },
      ],
      tbody: [],
      pageData: {},
      activeIds: [], //表格多选的id
      openChangeCategoryDialog: false, //是否显示修改分类弹窗
    };
  },
  methods: {
    //获取列表
    getList(pageData) {
      this.loading = true;
      return list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //筛选回调
    onFilter(filterObj) {
      Object.assign(this.postData, filterObj, { page: 1 });
      this.getList();
    },
    //tab切换事件
    handleTabClick() {
      //tab name已经v-model绑定到postData
      Object.assign(this.postData, { page: 1 });
      this.getList();
    },
    //排序事件 descending=倒序，ascending=升序,api默认倒序，所以null=descending
    sortChange({ prop, order }) {
      const is_desc = order === "ascending" ? 0 : 1;
      Object.assign(this.postData, { order_by: prop, is_desc });
      this.getList();
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
    //table多选事件
    handleSelectionChange(arr) {
      if (arr.length) {
        this.activeIds = arr.map((item) => item.id);
      } else {
        this.activeIds = [];
      }
    },
    //更多按钮点击事件
    onMoreCommand({ type, id }) {
      switch (type) {
        case "delete": //删除
          this.onDelete([id]);
          break;
        case "reduction": //还原
          this.onReduction([id]);
          break;
        case "changeLog": //变更记录
          this.onViewChangeLog(id);
          break;
        case "startLog": //开课记录
          break;
      }
    },
    //单个删除
    onDelete(ids, multiple = false) {
      let message = `您是否要删除${multiple ? "选中的" : "该条"}数据？`;
      if (this.postData.tab === "deleted") message += "删除后将不可恢复！";
      const api = this.postData.tab === "deleted" ? handleDel : softDelete;

      deleteMsgbox({
        message,
        confirmCallback: () => {
          return new Promise((resolve, reject) => {
            const postData = { ids };
            api(postData)
              .then((res) => {
                const { msg } = res;
                this.$message.success(msg);

                this.getList();
                return resolve(true);
              })
              .catch(() => {
                return resolve(true);
              });
          });
        },
      });
    },
    //多选删除
    onMultipleDelete() {
      if (this.activeIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onDelete(this.activeIds, true);
    },
    //单个还原
    onReduction(ids, multiple = false) {
      const message = `是否还原${multiple ? "选中的" : "该条"}数据？`;

      reductionMsgbox({
        message,
        confirmCallback: () => {
          return new Promise((resolve, reject) => {
            const postData = { ids };
            putBack(postData)
              .then((res) => {
                const { msg } = res;
                this.$message.success(msg);

                this.getList();
                return resolve(true);
              })
              .catch(() => {
                return resolve(true);
              });
          });
        },
      });
    },
    //多个还原
    onMultipleReduction() {
      if (this.activeIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.onReduction(this.activeIds, true);
    },
    //上传
    onAdd() {
      this.$router.push({ name: "ClassroomMaterialManageAddEdit" });
    },
    //编辑
    onEdit(id) {
      this.$router.push({
        name: "ClassroomMaterialManageAddEdit",
        query: { materialId: id },
      });
    },
    //编辑分类
    onopenChangeCategoryDialog() {
      if (this.activeIds.length <= 0) {
        return this.$message("没有检测到勾选的数据！");
      }
      this.openChangeCategoryDialog = true;
    },
    //查看变更记录
    onViewChangeLog(id) {
      this.$router.push({
        name: "ClassroomMaterialManageChangeLog",
        params: { materialId: id },
      });
    },
  },
  created() {
    this.getList();
  },
  components: {
    ManageFilter,
    Pagination,
    MoreBtn,
    ChangeCategoryDialog,
  },
};
</script>

<style lang="scss" scoped>
</style>
