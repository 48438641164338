/*
 * @Author: mulingyuer
 * @Date: 2021-09-01 15:39:53
 * @LastEditTime: 2021-09-02 10:09:06
 * @LastEditors: mulingyuer
 * @Description: 上传&修改素材
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\api\material-manage\add-edit.js
 * 怎么可能会有bug！！！
 */
import api from '@/base/utils/request'

//素材分类选项数据
export const category = (data) => {
  return api({
    url: '/admin/classroom/res_category/all',
    method: 'post',
    data,
  })
}

//保存
export const save = (data) => {
  return api({
    url: '/admin/classroom/res/save',
    method: 'post',
    data,
  })
}

//获取编辑的素材详情
export const detail = (data) => {
  return api({
    url: '/admin/classroom/res/detail',
    method: 'post',
    data,
  })
}

// 课程素材分页加载
export const resPagination = (data) => {
  return api({
    url: '/admin/classroom/course/chapterPaginator',
    method: 'post',
    data,
  })
}
