<!--
 * @Author: mulingyuer
 * @Date: 2021-09-08 15:36:05
 * @LastEditTime: 2022-02-09 17:08:23
 * @LastEditors: aleaner
 * @Description: 高级设置
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\online-classroom\components\course-manage\add-edit\HighRanking.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular high-ranking">
    <h2 class="modular-title">高级设置</h2>
    <div class="modular-content">
      <el-form-item label="会员查看权限：" prop="show_type">
        <el-radio-group v-model="form.show_type">
          <el-radio v-for="ra in showTypeArr" :key="ra.id" :label="ra.id">{{ra.name}}</el-radio>
        </el-radio-group>
        <div class="info"><i class="el-icon-info"></i>如下方的【非会员查看权限】已设置为"可看"，则表示所有会员可看，此配置将被忽略</div>
      </el-form-item>
      <el-form-item v-if="isPart" label="选择学员：" prop="user_ids">
        <el-button @click="openSelectMember = true">选择会员</el-button>
        <UserSelected v-if="form.show_user_ids.length" :selected="form.show_user_ids" />
      </el-form-item>
      <el-form-item label="非会员查看权限：" prop="non_cycuser_show_type">
        <el-radio-group v-model="form.non_cycuser_show_type">
          <el-radio v-for="ra in nonCycUserShowTypeArr" :key="ra.id" :label="ra.id">{{ra.name}}</el-radio>
        </el-radio-group>
        <div class="info"><i class="el-icon-info"></i>非会员含未登录、已登录未入会的用户。如需控制未登录用户不可查看，请前往修改
          <router-link target="_blank" :to="{name: 'NonCycUserAuth'}">用户使用权限</router-link></div>
      </el-form-item>
      <el-form-item label="评论设置：" prop="comment_enable">
        <el-radio-group v-model="form.comment_enable">
          <el-radio v-for="ra in commentEnableArr" :key="ra.id" :label="ra.id">{{ra.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="评论审核：" prop="comment_check">
        <el-radio-group v-model="form.comment_check">
          <el-radio v-for="ra in commentCheckArr" :key="ra.id" :label="ra.id">{{ra.name}}</el-radio>
        </el-radio-group>
      </el-form-item>
    </div>
    <!-- 会员选择弹窗 -->
    <MemberSelector v-model="openSelectMember" :Members="form.show_user_ids" @changeSelect="handleSelect" />
  </div>
</template>

<script>
import MemberSelector from "@/modules/common/components/MemberSelector";
import UserSelected from "./UserSelected.vue";
export default {
  components: { MemberSelector, UserSelected },
  props: {
    //表单数据
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      //显示类型数据
      showTypeArr: [
        { name: "全部会员可看", id: 1 },
        { name: "部分会员可看", id: 2 },
        { name: "不可看", id: 0 },
      ],
      //非会员显示类型
      nonCycUserShowTypeArr: [
        { name: "可看", id: 1 },
        { name: "不可看", id: 0 },
      ],
      commentEnableArr: [
        { name: "允许评论", id: 1 },
        { name: "不允许评论", id: 0 },
      ],
      commentCheckArr: [
        { name: "先发后审", id: 0 },
        { name: "先审后发", id: 1 },
      ],
      openSelectMember: false, // 会员选择弹窗显示隐藏
    };
  },
  computed: {
    //是否部分会员
    isPart({ form }) {
      return form.show_type === 2;
    },
  },
  methods: {
    // 获取选择会员
    handleSelect(e) {
      this.form.show_user_ids = e;
      console.log(this.form.show_user_ids, "e");
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
