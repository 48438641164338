<!--
 * @Author: mulingyuer
 * @Date: 2021-08-23 17:38:51
 * @LastEditTime: 2021-09-02 15:24:52
 * @LastEditors: mulingyuer
 * @Description: 课程分类管理提示
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\sorting-category-manage\Alert.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="alert">
    <div class="alert-item">
      <i class="el-icon-sunny alert-pic"></i>
      <h2 class="alert-title">操作提示</h2>
      <div class="alert-body">
        <ul>
          <li>在一级分类后点击添加子类，可以增加子分类，排序决定该分类在同一级别的显示顺序</li>
          <li>最多支持两级分类，子分类删除后分类下的课程进入子分类；父分类删除后，其下的子分类也全部删除，课程进入“其他”分类</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
