<!--
 * @Author: mulingyuer
 * @Date: 2021-08-23 17:38:51
 * @LastEditTime: 2021-09-02 15:20:55
 * @LastEditors: mulingyuer
 * @Description: 变更日志提示
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\material-manage\change-log\Alert.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="alert">
    <div class="alert-item">
      <i class="el-icon-sunny alert-pic"></i>
      <h2 class="alert-title">操作提示</h2>
      <div class="alert-body">
        <ul>
          <li>暂时只提供文件更改的变更记录</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>
