<!--
 * @Author: mulingyuer
 * @Date: 2021-08-31 17:16:25
 * @LastEditTime: 2021-09-02 10:24:18
 * @LastEditors: mulingyuer
 * @Description: 素材上传
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\material-manage\add-edit\MaterialUpload.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular material-upload">
    <h2 class="modular-title">素材上传</h2>
    <div class="modular-content">
      <div class="upload-wrap" @click="onOpenMediaDialog">
        <div class="text">
          <i class="el-icon-upload"></i>{{isEdit?'重新上传':'上传文件'}}
        </div>
      </div>
      <p class="info">
        <i
          class="el-icon-info"></i>最多上传10个文件；音视频支持格式：mp4/flv/mp3，大小1G以内；文档支持格式：word/ppt/excel/pdf，大小100M以内
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    //完成后的回调
    complete: {
      type: Function,
      required: true,
    },
    //是否编辑模式
    isEdit: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    //打开媒体库弹窗
    onOpenMediaDialog() {
      this.$bus.emit("open-media-dialog", {
        confirm: this.complete,
        options: {
          type: ['image', 'video', 'audio', 'file'],
          multiple: !this.isEdit, //多选
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.material-upload {
  .upload-wrap {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px dashed;
    border-radius: 4px;
    font-size: 14px;
    color: $info;
    cursor: pointer;
    @include no-select();
    transition: color 0.25s, border-color 0.25s;
    .text i {
      margin-right: 5px;
    }

    &:hover {
      color: $primary;
    }
    &:active {
      opacity: 0.7;
    }
  }
  .info {
    margin-top: 15px;
    font-size: 14px;
    line-height: 1;
    color: $info;

    i {
      margin-right: 5px;
    }
  }
}
</style>
