<!--
 * @Author: mulingyuer
 * @Date: 2021-09-07 16:09:04
 * @LastEditTime: 2021-09-08 16:37:51
 * @LastEditors: mulingyuer
 * @Description: 独立课程
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\course-manage\add-edit\AloneCourse.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular alone-course">
    <h2 class="modular-title">开课内容</h2>
    <div class="modular-content">
      <el-form-item label="课程内容：" prop="validateRes">
        <el-input v-show="false" v-model="form.validateRes"></el-input>
        <el-button @click="onAdd">添加课程</el-button>
        <div class="info">
          <i class="el-icon-info">文档类完成条件为浏览位置/文档详情总长度，音视频完成条件为最大的播放时间点/总时长，最大播放时间点包括拖拽操作</i>
        </div>
        <!-- 素材列表 -->
        <div v-if="isRes" class="material">
          <div class="left">
            <TypeIcon :type="res.type" />
          </div>
          <div class="right">
            <h2 class="title">{{res.title|placeholder}}</h2>
          </div>
          <el-button class="delete" type="danger" size="mini" icon="el-icon-close" plain circle
            @click="onDelete"></el-button>
        </div>
      </el-form-item>
    </div>
  </div>
</template>

<script>
import TypeIcon from "../../default/TypeIcon.vue";
export default {
  props: {
    //表单数据
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      materialData: {}, //素材数据（弹窗获取的）
    };
  },
  computed: {
    //独立课程素材数据
    res({ form }) {
      return form.res;
    },
    //是否存在数据
    isRes({ res }) {
      return !!res;
    },
  },
  methods: {
    //添加课程
    onAdd() {
      this.$bus.emit("open-course-material-dialog", {
        confirm: ([data]) => {
          this.materialData = data;
          //更改表单数据
          const { id, title, type } = data;
          if (!this.isRes) {
            this.form.res = { id: "", res_id: id, title, type };
            this.form.validateRes = id;
          } else {
            Object.assign(this.form.res, { res_id: id, title, type });
            this.form.validateRes = id;
          }
        },
        options: {
          multiple: false, //是否多选
        },
      });
    },
    //删除素材
    onDelete() {
      this.form.res = null;
      this.form.validateRes = "";
    },
  },
  components: {
    TypeIcon,
  },
};
</script>

<style lang="scss" scoped>
.alone-course {
  .material {
    margin-top: 20px;
    display: flex;
    align-items: center;
    padding: 15px;
    border: 1px solid #dcdfe6;
    .left {
      flex-shrink: 0;
      margin-right: 10px;
    }
    .right {
      min-width: 0;
      flex-grow: 1;
      @include nowrap();
      .title {
        font-size: 16px;
        height: 36px;
        line-height: 36px;
        font-weight: normal;
        color: #333;
      }
    }
    .delete {
      margin-left: 10px;
      flex-shrink: 0;
    }
  }
}
</style>
