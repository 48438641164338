<!--
 * @Author: mulingyuer
 * @Date: 2021-08-31 18:06:08
 * @LastEditTime: 2021-09-02 11:16:17
 * @LastEditors: mulingyuer
 * @Description: 上传的文件列表
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\material-manage\add-edit\MateriaItem.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular material-item-wrap">
    <h2 class="modular-title">
      已选素材文件 <strong>{{ form.reses.length | placeholder }}</strong> 个
    </h2>
    <div class="modular-content">
      <div v-for="(item, index) in form.reses" :key="item.res.id" class="item">
        <div class="item-left">
          <div class="cover-wrap" @click="onChangeCover(item)">
            <img v-miniSrc="item.cover" />
          </div>
          <div class="info">
            <i class="el-icon-info"></i>素材封面建议尺寸为400px*400px
          </div>
        </div>
        <div class="item-right">
          <el-form
            ref="ruleForm"
            :model="item"
            label-width="100px"
            size="medium"
            class="medium-form"
          >
            <el-form-item label="素材文件：">
              <div class="material-name">
                <img class="type-icon" v-miniSrc="typeIcon" />
                <span class="file-name">{{ item.res.title }}</span>
                <span class="size">{{ item.res.file_size }} 上传成功</span>
              </div>
            </el-form-item>
            <el-form-item label="素材标题：">
              <el-input
                v-model="item.title"
                placeholder="请输入素材标题"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="素材分类："
              :prop="`${index}`"
              :rules="rules.category_id"
            >
              <div class="flex-align-center">
                <el-select
                  v-model="item.category_id"
                  placeholder="请选择素材分类"
                >
                  <el-option
                    v-for="op in categoryArr"
                    :key="op.id"
                    :label="op.name"
                    :value="op.id"
                  ></el-option>
                </el-select>
                <el-button
                  style="margin-left: 10px"
                  size="small"
                  type="primary"
                  @click="onAddCategory"
                  >添加分类</el-button
                >
              </div>
            </el-form-item>
            <div class="delete-wrap">
              <el-button
                icon="el-icon-delete"
                size="mini"
                @click="onDelete(item)"
              ></el-button>
            </div>
          </el-form>
        </div>
      </div>
      <div v-if="!form.reses.length" class="placeholder">暂无素材</div>
    </div>
    <!-- 新增&编辑分类 -->
    <AddEditCatDialog
      v-model="openAddEditCatDialog"
      :editData="{}"
      :fixPid="''"
      :mode="'create'"
      :complete="getCategory"
    />
  </div>
</template>

<script>
import { category } from '../../../api/material-manage/add-edit'
import AddEditCatDialog from '../../material-category-manage/AddEditCatDialog.vue'
export default {
  components: { AddEditCatDialog },
  props: {
    //上传的数据
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rules: {
        category_id: [
          {
            trigger: 'change',
            required: false,
            validator: (rule, value, callback) => {
              //从rule中拿到prop中的index下标
              const index = Number(rule.field)
              if (!Number.isFinite(index))
                throw new Error('校验发生错误，不是预期的下标数字')
              //校验
              const val = this.form.reses[index].category_id
              return callback()
            },
          },
        ],
      },
      categoryArr: [], //分类选项数据
      typeIcon: require('@/base/assets/images/media/icon_file.png'),
      openAddEditCatDialog: false, //是否显示新增编辑弹窗
    }
  },
  methods: {
    onAddCategory() {
      this.openAddEditCatDialog = true
    },
    //获取分类选项数据
    getCategory() {
      category()
        .then((res) => {
          const { data } = res
          this.categoryArr = data
        })
        .catch(() => {})
    },
    //修改封面图
    onChangeCover(item) {
      this.$bus.emit('open-media-dialog', {
        confirm: (data) => {
          item.cover = data[0].url
        },
        options: {
          type: ['image'],
        },
      })
    },
    //校验
    validator() {
      return new Promise((resolve, reject) => {
        const formArr = this.$refs.ruleForm
        if (!formArr || !formArr.length) {
          this.$message.warning('请上传素材')
          return reject(false)
        }
        //运行
        const list = formArr.map((form) => this.checkForm(form))
        Promise.all(list)
          .then(() => {
            return resolve(true)
          })
          .catch(() => {
            return reject(false)
          })
      })
    },
    //表单校验
    checkForm(form) {
      return new Promise((resolve, reject) => {
        form.validate((valid) => {
          if (valid) return resolve(true)
          return reject(false)
        })
      })
    },
    //删除
    onDelete(item) {
      this.$emit('delete', item.res.id)
    },
  },
  created() {
    this.getCategory()
  },
}
</script>

<style lang="scss" scoped>
.material-item-wrap {
  .modular-title strong {
    color: $danger;
  }
  .modular-content {
    .item {
      border: 1px solid #dcdfe6;
      padding: 15px;
      border-radius: 4px;
      display: flex;
      & + .item {
        margin-top: 30px;
      }
      .item-left {
        flex-shrink: 0;
        width: 280px;
        margin-right: 20px;

        .cover-wrap {
          height: 187px;
          border: 1px solid #dcdfe6;
          border-radius: 4px;
          overflow: hidden;
          cursor: pointer;
          transition: border-color 0.25s;
          &:hover {
            border-color: $primary;
          }
          img {
            width: 100%;
            height: 100%;
            vertical-align: top;
            object-fit: cover;
          }
        }
        .info {
          margin-top: 10px;
          font-size: 14px;
          line-height: 22px;
          color: $info;
          display: flex;
          i {
            margin-right: 5px;
            line-height: inherit;
          }
        }
      }
      .item-right {
        min-width: 0;
        flex-grow: 1;
        .material-name {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .type-icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
          .file-name {
            min-width: 0;
            flex-grow: 1;
            @include nowrap();
          }
          .size {
            flex-shrink: 0;
            color: $info;
          }
        }
        .delete-wrap {
          text-align: right;
        }
      }
    }

    //占位
    .placeholder {
      text-align: center;
      height: 100px;
      line-height: 100px;
      font-size: 14px;
      color: $info;
    }
  }
}
</style>
