<!--
 * @Author: mulingyuer
 * @Date: 2021-09-08 15:16:33
 * @LastEditTime: 2021-09-08 15:33:04
 * @LastEditors: mulingyuer
 * @Description: 类型图标
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\default\TypeIcon.vue
 * 怎么可能会有bug！！！
-->
<template>
  <span class="type-icon" :style={width,height}>
    <img v-miniSrc="imgSrc">
  </span>
</template>

<script>
const fileTypeIcon = require('@/base/assets/images/media/icon_file.png')

export default {
  props: {
    //类型
    type: {
      type: String,
      required: true,
      validator(type) {
        return typeof type === "string" && type.trim() !== "";

      },
    },
    width: {
      type: String,
      default: "30px",
    },
    height: {
      type: String,
      default: "30px",
    },
  },
  data() {
    return {
      iconData: {
        image: fileTypeIcon,
        video: fileTypeIcon,
        file: fileTypeIcon,
        audio: fileTypeIcon,
      },
    };
  },
  computed: {
    //图片src
    imgSrc({ type }) {
      switch (type) {
        case "image":
        case "video":
        case "file":
        case "audio":
          return this.iconData[type];
        default:
          throw new Error(`未知的类型：${type}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.type-icon {
  display: block;
  img {
    width: 100%;
    height: 100%;
    vertical-align: top;
    object-fit: cover;
  }
}
</style>
