/*
 * @Author: mulingyuer
 * @Date: 2021-09-02 12:01:51
 * @LastEditTime: 2021-09-02 13:56:59
 * @LastEditors: mulingyuer
 * @Description: 变更记录
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\api\material-manage\change-log.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//列表
export const list = data => {
  return api({
    url: "/admin/classroom/res/getChangeLogs",
    method: "post",
    data
  })
}
