<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 14:06:46
 * @LastEditTime: 2021-09-02 14:51:07
 * @LastEditors: mulingyuer
 * @Description: 查看弹窗
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\material-manage\change-log\ViewDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="变更记录" :visible.sync="show" width="500px" class="dialog-vertical view-dialog">
    <div class="content">
      <div class="item">
        <h2 class="item-title">变更前</h2>
        <div class="item-body">
          <p>
            <span class="label">文件名：</span>
            <span class="value">{{oldData.name|placeholder}}</span>
          </p>
          <p>
            <span class="label">文件类型：</span>
            <span class="value">{{oldData.type|placeholder}}</span>
          </p>
        </div>
      </div>
      <div class="item">
        <h2 class="item-title">变更后</h2>
        <div class="item-body">
          <p>
            <span class="label">文件名：</span>
            <span class="value">{{newData.name|placeholder}}</span>
          </p>
          <p>
            <span class="label">文件类型：</span>
            <span class="value">{{newData.type|placeholder}}</span>
          </p>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    //是否打开
    open: {
      type: Boolean,
      required: true,
    },
    //数据
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
    //之前的数据
    oldData({ data }) {
      return data.old || {};
    },
    //新的数据
    newData({ data }) {
      return data.new || {};
    },
  },
};
</script>

<style lang="scss" scoped>
.view-dialog {
  .content {
    .item {
      font-size: 14px;
      line-height: 22px;
      border: 1px solid #ebeef5;
      & + .item {
        margin-top: 30px;
      }

      .item-title {
        font-size: 16px;
        font-weight: normal;
        color: #333;
        padding: 10px 15px;
        background-color: #f0f2f5;
      }

      .item-body {
        padding: 20px 15px;
        p {
          display: flex;
          & + p {
            margin-top: 10px;
          }
          .label {
            flex-shrink: 0;
            width: 80px;
            color: #999;
          }
          .value {
            width: 0;
            flex-grow: 1;
            color: #303133;
          }
        }
      }
    }
  }
}
</style>
