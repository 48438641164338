<!--
 * @Author: dongjia
 * @Date: 2022-02-09 16:55:32
 * @LastEditTime: 2022-02-09 17:07:47
 * @LastEditors: aleaner
 * @Description: 已选择会员组件
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\online-classroom\components\course-manage\add-edit\UserSelected.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="member-list">
    <div class="list-top">
      <div class="top-left">已添加会员数：{{selected.length}}</div>
      <div class="top-right">
        <el-button type="text" class="clear-member" @click="clearMembers">清空</el-button>
      </div>
    </div>
    <div class="list-content">
      <el-tag class="list-item" :key="item.user_id" v-for="(item,index) in selected" closable
        @close="selected.splice(index,1)">
        {{item.nickname}}
      </el-tag>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Array,
      required: true,
    },
  },
  methods: {
    // 清空会员
    clearMembers() {
      this.selected.splice(0, this.selected.length);
    },
  },
};
</script>

<style lang="scss" scoped>
.member-list {
  margin-top: 20px;
  width: 400px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  .list-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    border-bottom: 1px solid #dcdfe6;
    .top-left {
      font-weight: bold;
    }
    .top-right {
      .clear-member {
        padding: 0;
      }
    }
  }
  .list-content {
    min-height: 52px;
    padding: 10px 0px 0 10px;
    .list-item {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>
