<!--
 * @Author: mulingyuer
 * @Date: 2021-08-25 10:30:03
 * @LastEditTime: 2021-08-25 14:35:56
 * @LastEditors: mulingyuer
 * @Description: 素材管理筛选
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\material-manage\index\Filter.vue
 * 怎么可能会有bug！！！
-->
<template>
  <LayoutFilter :onFilter="onFilter" :onReset="onReset">
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="70px" size="medium" class="filter-from">
      <div class="filter-item">
        <DatePeriodPicker label="创建时间：" start-placeholder="开始日期" :start-time.sync="form.create_start_time"
          start-prop="create_start_time" end-placeholder="结束日期" :end-time.sync="form.create_end_time"
          end-prop="create_end_time" />
      </div>
      <div class="filter-item">
        <el-form-item label="素材名称：" prop="keyword">
          <el-input v-model="form.keyword" placeholder="请输入素材名称"></el-input>
        </el-form-item>
        <el-form-item label="素材分类：" prop="category_id">
          <el-select v-model="form.category_id" placeholder="请选择活素材分类">
            <el-option v-for="op in categoryArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="创建人：" prop="user_keywprd">
          <el-input v-model="form.user_keywprd" placeholder="请输入创建人"></el-input>
        </el-form-item>
      </div>
      <div class="filter-item">
        <el-form-item label="素材类型：" prop="type">
          <el-select v-model="form.type" placeholder="请选择素材类型">
            <el-option v-for="op in typeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
          </el-select>
        </el-form-item>
      </div>
    </el-form>
  </LayoutFilter>
</template>

<script>
import LayoutFilter from "@/base/layout/LayoutFilter";
import DatePeriodPicker from "@/base/components/Form/DatePeriodPicker";
import { category } from "../../../api/material-manage/index";
export default {
  props: {
    filterChange: {
      required: true,
      type: Function,
    },
    //导出要用的数据
    exportData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: {
        create_start_time: -1, // 创建开始时间
        create_end_time: -1, // 创建结束时间
        keyword: "", //素材名称
        category_id: "", //素材分类
        user_keywprd: "", //创建人
        type: -1, //素材类型
      },
      rules: {},
      //素材类型选项数据
      typeArr: [
        { name: "全部", id: -1 },
        { name: "文档", id: 1 },
        { name: "图片", id: 2 },
        { name: "视频", id: 3 },
        { name: "音频", id: 4 },
      ],
      //素材分类选项数据
      categoryArr: [{ name: "全部", id: "" }],
    };
  },
  methods: {
    //重置
    onReset() {
      this.$refs["ruleForm"].resetFields();
      return this.onFilter();
    },
    //筛选
    onFilter() {
      return this.filterChange(Object.assign({}, this.form));
    },
    //导出事件
    onExport() {},
    //获取分类选项数据
    getCategoryArr() {
      category()
        .then((res) => {
          const { data } = res;
          this.categoryArr.push(...data);
        })
        .catch(() => {});
    },
  },
  created() {
    this.getCategoryArr();
  },
  components: {
    LayoutFilter,
    DatePeriodPicker,
  },
};
</script>

<style lang="scss" scoped>
</style>
