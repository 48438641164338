/*
 * @Author: mulingyuer
 * @Date: 2021-09-07 14:59:26
 * @LastEditTime: 2022-02-09 16:53:01
 * @LastEditors: aleaner
 * @Description: 新增&编辑课程
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\online-classroom\api\course-manage\add-edit.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//获取课程分类选项数据
export const category = data => {
  return api({
    url: "/admin/classroom/course_category/all",
    method: "post",
    data
  })
}

//获取素材列表
export const list = data => {
  return api({
    url: "/admin/classroom/res/dialogSelect",
    method: "post",
    data
  })
}


//获取会员列表
export const userList = data => {
  return api({
    url: "/admin/cyc/organization/members",
    method: "post",
    data
  })
}


//保存
export const save = data => {
  return api({
    url: "/admin/classroom/course/save",
    method: "post",
    data
  })
}

//详情数据
export const detail = data => {
  return api({
    url: "/admin/classroom/course/detail",
    method: "post",
    data
  })
}