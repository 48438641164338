<!--
 * @Author: mulingyuer
 * @Date: 2021-09-01 17:02:38
 * @LastEditTime: 2021-09-07 15:33:17
 * @LastEditors: mulingyuer
 * @Description: 属性设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\components\material-manage\add-edit\AttributeSetting.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="modular attribute-setting">
    <h2 class="modular-title">属性设置
      <Help :content="helpContent" />
    </h2>
    <div class="modular-content">
      <el-form ref="ruleForm" :model="form" :rules="rules" label-width="100px" size="medium"
        class="medium-form">
        <el-form-item label="适用人群：" prop="for_people" class="for-people">
          <el-input v-model="form.for_people"></el-input>
        </el-form-item>
        <el-form-item label="素材介绍：" prop="description" class="description">
          <el-input v-show="false" v-model="form.description" type="textarea"></el-input>
          <SuperTinymce v-model="form.description" />
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import Help from "@/base/components/Popover/Help.vue";
import SuperTinymce from "@/modules/common/components/SuperTinymce.vue";
export default {
  props: {
    //上传的数据
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      helpContent:
        "将对多个素材进行以下设置以下属性，若需要对单个素材设置属性，可保存后重新编辑素材",
      rules: {},
    };
  },
  methods: {
    //校验
    validator() {
      return new Promise((resolve, reject) => {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) return resolve(true);
          return reject(false);
        });
      });
    },
  },
  components: {
    Help,
    SuperTinymce,
  },
};
</script>

<style lang="scss" scoped>
.attribute-setting {
  .medium-form {
    .for-people {
      .el-input {
        max-width: 700px;
      }
    }
    .description {
      max-width: 800px;
    }
  }
}
</style>
