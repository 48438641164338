/*
 * @Author: mulingyuer
 * @Date: 2021-09-02 15:25:31
 * @LastEditTime: 2021-09-07 11:34:06
 * @LastEditors: mulingyuer
 * @Description: 课程分类管理
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\api\sorting-category-manage.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//列表
export const list = data => {
  return api({
    url: "/admin/classroom/course_category/index",
    method: "post",
    data
  })
}


//修改排序
export const changeSort = data => {
  return api({
    url: "/admin/classroom/course_category/updateSort",
    method: "post",
    data
  })
}

//修改状态
export const changeStatus = data => {
  return api({
    url: "/admin/classroom/course_category/updateStatus",
    method: "post",
    data
  })
}


//分类选项数据
export const category = data => {
  return api({
    url: "/admin/classroom/course_category/allParents",
    method: "post",
    data
  })
}

//转移
export const moveCategory = data => {
  return api({
    url: "/admin/classroom/course_category/move",
    method: "post",
    data
  })
}

//删除
export const deleteCategory = data => {
  return api({
    url: "/admin/classroom/course_category/del",
    method: "post",
    data
  })
}


//新增分类
export const addCategory = data => {
  return api({
    url: "/admin/classroom/course_category/save",
    method: "post",
    data
  })
}