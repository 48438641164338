<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 11:40:51
 * @LastEditTime: 2021-09-02 14:35:07
 * @LastEditors: mulingyuer
 * @Description: 变更记录
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\material-manage\change-log.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="table-page change-log">
    <div class="head">
      <Alert />
    </div>
    <div class="content" v-loading="loading">
      <el-table class="thead-light" :data="tbody" style="width: 100%" stripe>
        <el-table-column v-for="th in thead" :key="th.prop" :prop="th.prop" :label="th.label"
          :minWidth="th.minWidth" :sortable="th.sort" :fixed="th.fixed" :show-overflow-tooltip="th.tooltip">
          <template slot-scope="{row}">
            <!-- 操作 -->
            <template v-if="th.prop==='operate'">
              <el-button type="text" size="mini" @click="onOpenViewDialog(row.config)">查看</el-button>
            </template>
            <!-- 类型 -->
            <template v-else-if="th.prop==='type'">
              {{row.config.type|placeholder}}
            </template>
            <!-- 其他 -->
            <template v-else>{{row[th.prop]|placeholder}}</template>
          </template>
        </el-table-column>
      </el-table>
      <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
    </div>
    <!-- 查看弹窗 -->
    <ViewDialog v-model="openViewDialog" :data="viewDialogData" />
  </div>
</template>

<script>
import Alert from "../../components/material-manage/change-log/Alert.vue";
import Pagination from "@/base/components/Default/Pagination";
import ViewDialog from "../../components/material-manage/change-log/ViewDialog.vue";
import { list } from "../../api/material-manage/change-log";
export default {
  props: {
    //素材id
    materialId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loading: false, //加载中
      postData: {
        id: this.materialId,
      },
      thead: [
        { label: "变更人", prop: "admin_user_name", minWidth: 120 },
        { label: "变更时间", prop: "create_time", minWidth: 180 },
        { label: "变更类型", prop: "type", minWidth: 120 },
        { label: "变更详情", prop: "operate", minWidth: 120, fixed: "right" },
      ],
      tbody: [],
      pageData: {},
      openViewDialog: false, //是否显示查看弹窗
      viewDialogData: {}, //查看弹窗的数据
    };
  },
  methods: {
    //获取列表
    getList(pageData) {
      this.loading = true;
      list(pageData || this.postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getList();
    },
    //查看弹窗
    onOpenViewDialog(data) {
      this.viewDialogData = data;
      this.openViewDialog = true;
    },
  },
  created() {
    this.getList();
  },
  watch: {
    materialId(val) {
      this.postData.id = val;
    },
  },
  components: {
    Alert,
    Pagination,
    ViewDialog,
  },
};
</script>

<style lang="scss" scoped>
</style>