<!--
 * @Author: mulingyuer
 * @Date: 2021-09-07 14:11:27
 * @LastEditTime: 2022-02-09 17:15:52
 * @LastEditors: aleaner
 * @Description: 课程编辑&新增
 * @FilePath: \buildd:\hujiang-project\saas-admin-vue\src\modules\online-classroom\views\course-manage\add-edit.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="add-edit">
    <div class="content" v-loading="loading">
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-width="110px"
        size="medium"
        class="medium-form"
        @submit.native.prevent
      >
        <!-- 基础设置 -->
        <BasicInfo
          :form="form"
          :getList="getCategory"
          :categoryArr="categoryArr"
          :isAlone="isAlone"
        />
        <!-- 独立课程 -->
        <AloneCourse v-if="isAlone" :form="form" />
        <!-- 章节课程 -->
        <ChapterCourse v-else :form="form" />
        <!-- 高级设置 -->
        <HighRanking :form="form" />
      </el-form>
    </div>
    <FixedActionBar :z-index="2">
      <el-button
        v-if="!loading"
        type="primary"
        size="medium"
        :loading="saveLoading"
        @click="onSave"
      >
        保存
      </el-button>
      <el-button size="medium" @click="onCancel"> 取消 </el-button>
    </FixedActionBar>
    <!-- 素材弹窗 -->
    <MaterialDialog />
  </div>
</template>

<script>
import BasicInfo from '../../components/course-manage/add-edit/BasicInfo.vue'
import AloneCourse from '../../components/course-manage/add-edit/AloneCourse.vue'
import ChapterCourse from '../../components/course-manage/add-edit/ChapterCourse.vue'
import MaterialDialog from '../../components/course-manage/add-edit/MaterialDialog.vue'
import HighRanking from '../../components/course-manage/add-edit/HighRanking.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import { category, save, detail } from '../../api/course-manage/add-edit'
import { objectFilter } from '@/base/utils/tool'
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        id: '', //新增时为空
        name: '', //课程名称
        description_type: 0,
        teacher: {
          id: '',
          name: '',
        },
        is_finished: 0,
        source_type: 0,
        category_id: '', //课程分类
        type: 0, //课程类型 0:独立课程，1:章节课程
        cover: '', //课程封面
        description: '', //课程介绍
        chapters: [], //章节课程内容
        res: null, //独立课程
        validateRes: '', //辅助校验res（提交需过滤）
        show_type: 1, //显示范围 ：0:不显示，1:全部显示，2:指定会员显示
        show_user_ids: [], //用户id数组
        non_cycuser_show_type: 0, //非会员显示（0：不显示，1：显示）
        comment_enable: 1, //评论设置 0: 不允许评论，1:允许评论
        comment_check: 0, //评论审核 0 先发后审，1:先审后发
        third_party_category: '', //标签
      },
      rules: {
        name: [{ required: true, message: '请输入课程名称', trigger: 'blur' }],
        category_id: [
          { required: true, message: '请选择课程分类', trigger: 'change' },
        ],
        chapters: [
          {
            type: 'array',
            message: '请添加课程',
            trigger: 'change',
          },
        ],
        validateRes: [
          { required: true, message: '请添加课程', trigger: 'change' },
        ],
      },
      categoryArr: [], //分类选项数据
      saveLoading: false, //保存中
      editData: {}, //编辑的数据
    }
  },
  computed: {
    //是否独立课程
    isAlone({ form }) {
      return form.type === 0
    },
    //编辑的课程id
    courseId({ $route }) {
      return $route.query.courseId
    },
    //是否编辑模式
    isEdit({ courseId }) {
      return typeof courseId !== 'undefined'
    },
  },
  methods: {
    //获取分类选项数据
    getCategory() {
      return new Promise((resolve, reject) => {
        category()
          .then((res) => {
            const { data } = res
            this.categoryArr = data

            return resolve(true)
          })
          .catch((error) => {
            return reject(error)
          })
      })
    },
    //保存
    onSave() {
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          //过滤不需要的属性
          let postData = objectFilter({ ...this.form }, 'validateRes', 'uuid')
          // 文章来源如果不是自建的，章节过滤不上传
          if(postData.source_type !== 0) {
            postData = objectFilter({ ...this.form }, 'chapters')
          }
          postData.show_user_ids = postData.show_user_ids.map(
            (el) => el.user_id
          )
          save(postData)
            .then((res) => {
              const { msg } = res
              this.$message.success(msg)
              this.$router.go(-1)
              this.resetForm()
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    },
    //重置表单
    resetForm() {
      this.$refs['ruleForm'].resetFields()
    },
    //取消
    onCancel() {
      this.$router.go(-1)
    },
    //获取详情
    getDetail() {
      return new Promise((resolve, reject) => {
        const postData = { id: this.courseId }
        detail(postData)
          .then((res) => {
            const { data } = res
            this.editData = data

            this.initForm()
            return resolve(true)
          })
          .catch((error) => {
            return reject(error)
          })
      })
    },
    //初始化表单
    initForm() {
      Object.keys(this.form).forEach((key) => {
        if (key === 'validateRes') {
          this.editData.res && (this.form[key] = this.editData.res.res_id)
        } else {
          this.form[key] = this.editData[key]
        }
      })
    },
  },
  async created() {
    // 如果是云学院版本，默认非会员也显示
    if (this.$store.getters.tenant.version === 'college') {
      this.form.non_cycuser_show_type = 1
    }
    try {
      this.loading = true
      await this.getCategory()

      if (this.isEdit) await this.getDetail()

      this.loading = false
    } catch (error) {
      console.error(error)
      this.loading = false
    }
  },
  beforeRouteEnter(to, from, next) {
    const courseId = to.query.courseId
    if (typeof courseId !== 'undefined') {
      to.meta.title = '编辑课程'
    } else {
      to.meta.title = '新增课程'
    }
    next()
  },
  components: {
    FixedActionBar,
    BasicInfo,
    AloneCourse,
    MaterialDialog,
    HighRanking,
    ChapterCourse,
  },
}
</script>

<style lang="scss" scoped></style>
