/*
 * @Author: mulingyuer
 * @Date: 2021-08-25 10:36:57
 * @LastEditTime: 2021-09-02 11:26:15
 * @LastEditors: mulingyuer
 * @Description: 素材管理
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\api\material-manage\index.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";


//分类选项数据
export const category = data => {
  return api({
    url: "/admin/classroom/res_category/all",
    method: "post",
    data
  })
}


//获取列表
export const list = data => {
  return api({
    url: "/admin/classroom/res/index",
    method: "post",
    data
  })
}


//软删除
export const softDelete = data => {
  return api({
    url: "/admin/classroom/res/softDelete",
    method: "post",
    data
  })
}

//硬删除
export const handleDel = data => {
  return api({
    url: "/admin/classroom/res/del",
    method: "post",
    data
  })
}

//还原
export const putBack = data => {
  return api({
    url: "/admin/classroom/res/putBack",
    method: "post",
    data
  })
}


//修改分类
export const changeCategory = data => {
  return api({
    url: "/admin/classroom/res/updateCategory",
    method: "post",
    data
  })
}