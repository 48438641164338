<!--
 * @Author: mulingyuer
 * @Date: 2021-05-27 18:20:26
 * @LastEditTime: 2021-05-28 15:38:34
 * @LastEditors: mulingyuer
 * @Description: 超级后台的文本编辑器
 * @FilePath: \super-admin-vue\src\modules\common\components\superTinymce.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="super-tinymce">
    <Tinymce ref="template" v-model="article_content" v-bind="$attrs" :show-article-import="showArticleImport" @choose="onChoose"></Tinymce>
    <MediaSelector v-model="openMediaSelector" :type="openType" multiple @select="onSelect" />
<!--    <import-article v-model="showImport" @confirm="getImportDetail"></import-article>-->
    <import-html-code v-model="showInput"></import-html-code>
  </div>
</template>

<script>
import Tinymce from "@/base/components/Editor/Tinymce";
import MediaSelector from "@/modules/common/components/MediaSelector";
import ImportHtmlCode from "@/base/components/Editor/ImportHtmlCode";
// import ImportArticle from "./ImportArticle";
export default {
  inheritAttrs: false, //禁止继承attribute
  model: {
    prop: "content",
    event: "handleContent",
  },
  props: {
    //文章内容
    content: {
      type: String,
      required: true,
    },
    showArticleImport: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      openType: "image", // 媒体库弹窗模式
      openMediaSelector: false, //是否显示媒体库弹窗
      supportArr: ["image", "video", "audio"], //支持的弹窗类型
      tinymce: null, //tinymce全局实例
      showImport: false, // 文章导入
      showInput: false, // HTML导入
    };
  },
  computed: {
    article_content: {
      get() {
        return this.content;
      },
      set(val) {
        this.$emit("handleContent", val);
      },
    },
  },
  methods: {
    getImportDetail(e) {
      this.article_content = e.content;
      this.$refs.template.$emit("change", e.content);
    },
    // 富文本框点击图片，视频，音频返回事件
    onChoose({ type }) {
      if (this.supportArr.includes(type)) {
        this.openType = type;
        this.openMediaSelector = true;
      } else if (type === 'import') {
        this.showImport = true;
      } else if (type === 'html') {
        this.showInput = true;
      } else {
        console.log("不支持的触发格式");
      }
    },
    //媒体库选中后的回调
    onSelect(data) {
      if (!this.tinymce && typeof window.tinymce !== "undefined") {
        this.tinymce = window.tinymce;
      }
      switch (this.openType) {
        case "image":
          this.onImages(data);
          break;
        case "video":
          this.onVideo(data);
          break;
        case "audio":
          this.onAudio(data);
          break;
        default:
          console.log("暂不支持插入的文件类型");
      }
      this.openMediaSelector = false; //关闭弹窗
    },
    //插入图片
    onImages(imgArr) {
      imgArr.forEach((item) => {
        this.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<img src="${item.url}"/>`
        );
      });
    },
    //插入视频
    onVideo(videoArr) {
      videoArr.forEach((item) => {
        this.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-preview-object mce-object-video"
                contenteditable="false"
                data-mce-object="video"
                data-mce-p-controls="controls"
                data-mce-p-controlslist="nodownload"
                data-mce-p-allowfullscreen="true"
                data-mce-p-frameborder="no"
                data-mce-p-scrolling="no"
                data-mce-html=""
                data-mce-p-src="${item.url}">
                <video controls src="${item.url}"></video></span></p>
                <p><br/></p>`
        );
      });
    },
    //插入音频
    onAudio(audioArr) {
      audioArr.forEach((item) => {
        this.tinymce.execCommand(
          "mceReplaceContent",
          false,
          `<p><span class="mce-object-audio"
            contenteditable="false"
            data-mce-object="audio"
            data-mce-p-controls="controls"
            data-mce-p-controlslist="nodownload"
            data-mce-p-frameborder="no"
            data-mce-p-scrolling="no"
            data-mce-html=""
            data-mce-p-src="${item.url}">
            <audio controls src="${item.url}"></audio></span></p>
            <p><br/></p>`
        );
      });
    },
  },
  components: {
    ImportHtmlCode,
    // ImportArticle,
    Tinymce,
    MediaSelector,
  },
};
</script>

<style lang="scss" scoped>
</style>
