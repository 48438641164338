/*
 * @Author: mulingyuer
 * @Date: 2021-08-23 17:52:31
 * @LastEditTime: 2021-09-07 11:46:49
 * @LastEditors: mulingyuer
 * @Description: 素材分类管理
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\api\material-category-manage.js
 * 怎么可能会有bug！！！
 */
import api from "@/base/utils/request";

//获取分类列表
export const list = data => {
  return api({
    url: "/admin/classroom/res_category/index",
    method: "post",
    data
  })
}

//新增分类
export const addCategory = data => {
  return api({
    url: "/admin/classroom/res_category/save",
    method: "post",
    data
  })
}

//修改排序
export const changeSort = data => {
  return api({
    url: "/admin/classroom/res_category/updateSort",
    method: "post",
    data
  })
}


//修改状态
export const changeStatus = data => {
  return api({
    url: "/admin/classroom/res_category/updateStatus",
    method: "post",
    data
  })
}

//分类选项数据
export const category = data => {
  return api({
    url: "/admin/classroom/res_category/allParents",
    method: "post",
    data
  })
}

//删除分类
export const deleteCategory = data => {
  return api({
    url: "/admin/classroom/res_category/del",
    method: "post",
    data
  })
}


//迁移
export const moveCategory = data => {
  return api({
    url: "/admin/classroom/res_category/move",
    method: "post",
    data
  })
}